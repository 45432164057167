import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/about": [5],
		"/account": [6],
		"/features/funeral": [~7],
		"/features/jobs": [~8],
		"/features/loans": [~9],
		"/features/medical": [~10],
		"/features/mobile": [~11],
		"/features/pet": [~12],
		"/features/surveys": [~13,[2]],
		"/features/surveys/bank-details": [14,[2]],
		"/features/surveys/history": [15,[2]],
		"/features/surveys/wallet": [16,[2]],
		"/forgot-password": [17],
		"/home": [~18],
		"/privacy": [19],
		"/profile": [20],
		"/profile/[featureId]": [21],
		"/reset-password": [22],
		"/signin": [23],
		"/signup": [~24],
		"/signup/[featureId]": [~25],
		"/terms": [26],
		"/test/providers": [27],
		"/unverified-email": [~28],
		"/[featureId]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';